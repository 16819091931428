import { createIcon } from '@chakra-ui/icons';

export const MapPinIcon = createIcon({
  displayName: 'MapPinIcon',
  viewBox: '0 0 128 176.716',
  path: (
    <g transform="translate(-70.573)">
      <circle
        cx="47"
        cy="47"
        r="47"
        transform="translate(87.573 17.5)"
        fill="#f9f9f9"
        opacity="0.7"
      />
      <path
        fill="currentColor"
        d="M134.573,0a64.072,64.072,0,0,0-64,64c0,43.8,57.274,108.089,59.712,110.8a5.765,5.765,0,0,0,8.576,0c2.438-2.716,59.712-67.01,59.712-110.8A64.073,64.073,0,0,0,134.573,0Zm0,93.2c-14.6,0-29.2-14.6-29.2-29.2s14.6-29.2,29.2-29.2,29.2,14.6,29.2,29.2S149.173,93.2,134.573,93.2Z"
      />
    </g>
  ),
});
